<template>
  <div class="md-layout">
    <CSLoading :is-loading="isLoading"></CSLoading>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>conveyor_belt</md-icon>
          </div>
          <h4 class="title">{{ $t("layout.rulesSQP") }}</h4>
          <md-button v-if="hasPermission('Write', 'Orders', '')" @click="createModal = true" class="md-icon-button md-success add-button">
            <md-icon>add</md-icon>
          </md-button>
        </md-card-header>
        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-25">
              <md-field md-clearable>
                <label>{{ $t("orderRules.keywords") }}</label>
                <md-input v-model="search" @input="debounceApplyFilters"></md-input>
              </md-field>
            </div>
          </div>
          <md-table v-model="rules.mdData" table-header-color="green" class="table-sticky" md-sort="createdAt" md-sort-order="desc" @sort="sortData">
            <md-table-empty-state md-label="No files found" :md-description="`No file found. Try a different search term.`"> </md-table-empty-state>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell :md-label="$t('orderRules.id')"> {{ item.id }} </md-table-cell>
              <md-table-cell :md-label="$t('orderRules.ruleVersion')">
                {{ item.ruleVersion }}
              </md-table-cell>
              <md-table-cell :md-label="$t('orderRules.sku')">
                <span v-for="n in item.skus" :key="n" class="sku-item-chip">
                  <md-chip>{{ n }}</md-chip>
                </span>
              </md-table-cell>
              <md-table-cell :md-label="$t('orderRules.creationDate')"> {{ item.creationDate | formatDateTime }} </md-table-cell>
              <md-table-cell :md-label="$t('orderRules.actions')">
                <div v-if="hasPermission('Write', 'Orders', '')" class="md-table-cell-container cell-actions table-actions">
                  <md-button class="md-icon-button" @click="showEditModal(item)" :disabled="isLoading"
                    ><md-icon>edit</md-icon> <md-tooltip md-direction="top">{{ $t("orderRules.edit") }}</md-tooltip></md-button
                  >
                  <md-button class="md-icon-button md-danger" @click="showDeleteModal(item)"
                    ><md-icon>delete</md-icon> <md-tooltip md-direction="top">{{ $t("orderRules.delete") }}</md-tooltip></md-button
                  >
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <md-pagination :md-update="updatePagination" :md-data.sync="rules" :md-page-size="pageSize" :md-page-options="[10, 20, 50, 100]" />
        </md-card-content>
        <modal v-if="editModal" @close="hideEditModal" class-name="modal-post">
          <template slot="header">
            <h4 class="modal-title">{{ $t("orderRules.edit") }}</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideEditModal">
              <md-icon>clear</md-icon>
            </md-button>
          </template>
          <template slot="body">
            <RuleForm :data="post" @post-updated="afterSaved" @cancel="hideEditModal" />
          </template>
        </modal>
        <modal v-if="createModal" @close="hideCreateModal" class-name="modal-post">
          <template slot="header">
            <h4 class="modal-title">{{ $t("orderRules.create") }}</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideCreateModal">
              <md-icon>clear</md-icon>
            </md-button>
          </template>
          <template slot="body">
            <RuleForm :data="post" @post-created="afterSaved" @cancel="hideCreateModal" />
          </template>
        </modal>
        <md-dialog :md-active.sync="deleteModal" :md-click-outside-to-close="false">
          <md-dialog-title> {{ $t("orderRules.confirmDelete") }} </md-dialog-title>
          <md-dialog-content v-html="confirmDeleteMessage"> </md-dialog-content>

          <md-dialog-actions>
            <md-button class="md-secondary" @click="hideDeleteModal">{{ $t("orderRules.cancel") }}</md-button>
            <md-button class="md-danger" @click="deleteRule">{{ $t("orderRules.delete") }}</md-button>
          </md-dialog-actions>
        </md-dialog>
      </md-card>
    </div>
  </div>
</template>
<script>
import CSLoading from "../../components/Loading/CSLoading.vue";
import { hasPermission } from "../../mixins/auth";

import OrdersService from "../../services/orders.service";
import { MdPagination } from "@/components";
import { debounce } from "lodash";
import { Modal } from "@/components";
import RuleForm from "../../components/Orders/RuleForm";
import ordersService from "../../services/orders.service";

export default {
  components: {
    CSLoading,
    MdPagination,
    Modal,
    RuleForm,
  },
  data() {
    return {
      editModal: false,
      createModal: false,
      deleteModal: false,
      isLoading: true,
      items: [],
      currentPage: 1,
      pageSize: 20,
      orderBy: "creationDate",
      ascending: false,
      totalItems: 0,
      search: "",
      rules: {
        mdCount: null,
        mdPage: null,
        mdData: [],
      },
      post: null,
    };
  },
  computed: {
    confirmDeleteMessage() {
      return this.$t("orderRules.confirmDeleteMessage", {
        id: this.post?.id,
      });
    },
  },
  methods: {
    showCreateModal() {
      this.post = null;
      this.createModal = true;
    },
    hideCreateModal() {
      this.createModal = false;
      this.post = null;
    },
    showEditModal(post) {
      this.post = post;
      this.editModal = true;
    },
    hideEditModal() {
      this.editModal = false;
      this.post = null;
    },
    showDeleteModal(post) {
      this.post = post;
      this.deleteModal = true;
    },
    hideDeleteModal() {
      this.deleteModal = false;
      this.post = null;
    },
    async deleteRule() {
      try {
        await ordersService.deleteRule(this.post.id);
        this.$notify({
          timeout: 2500,
          message: this.$t("orderRules.messages.deleted"),
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "success",
        });
        this.hideDeleteModal();

        this.getData();
      } catch (error) {
        this.$notify({
          timeout: 2500,
          message: this.$t("orderRules.messages.deleteError"),
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "error",
        });
      }
    },
    afterSaved() {
      this.hideCreateModal();
      this.hideEditModal();

      this.$notify({
        timeout: 2500,
        message: this.$t("orderRules.messages.saved"),
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success",
      });
      this.getData();

      // return this.$store.dispatch("posts/getAll");
    },
    hasPermission(permissionName, tableName, columnName) {
      return hasPermission(permissionName, tableName, columnName);
    },
    updatePagination(page, pageSize) {
      this.currentPage = page || 1;
      this.pageSize = pageSize;
      this.getData();
    },
    debounceApplyFilters: debounce(function () {
      this.getData();
    }, 300),

    sortData(args) {
      this.orderBy = orderBy;
      this.ascending = ascending;
      this.getData();
    },
    getData(params) {
      this.isLoading = true;
      const _params = {
        keyword: this.search,
        // pageIndex: this.currentPage,
        // pageSize: this.pageSize,
        // orderBy: this.orderBy,
        // ascending: this.ascending,
        // fileType: this.fileType,
      };
      OrdersService.getPickTypeRules({ ..._params, ...params })
        .then((response) => {
          this.rules = { mdData: response.items, mdCount: response.totalItems || response.items.length, mdPage: response.pageIndex || 1 };
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notify({
            timeout: 2500,
            message: this.$t("orderRules.messages.fetchDataError"),
            icon: "error",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
        });
    },
  },
  created() {
    this.updatePagination(1, this.pageSize);

    this.$store.dispatch("shippingData/getProductSKUs", { pageSize: 10000 });
    // Promise.all([this.getData()]).finally((_) => {
    //   this.isLoading = false;
    // });
  },
};
</script>
<style lang="scss">
.sku-item-chip {
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>
