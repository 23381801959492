import axiosFactory from "./axios.factory";
import BaseService from "./base.service";

/**
 * File Service
 * @class FileService
 * @extends BaseService
 */
class OrdersService extends BaseService {
  async getPickTypeRules(params) {
    return axiosFactory()
      .get(`${this.entity}/picktype_import_rules`, { params })
      .then((res) => {
        if (!res.data?.data?.length) {
          return {
            items: [],
            totalItems: 0,
          };
        }
        const items = res.data.data.map((element) => {
          let skus = [];
          Object.keys(element).forEach((key) => {
            if (key.includes("sku") && element[key]) {
              skus.push(element[key]);
            }
          });
          return {
            id: element.id,
            ruleVersion: element.ruleVersion,
            creationDate: element.creationDate,
            skus: skus,
          };
        });
        return {
          items,
          totalItems: items.length,
        };
      });
  }
  generateSKUList(skus) {
    let skuList = {};
    skus.forEach((sku, index) => {
      skuList[`sku${index + 1}`] = sku;
    });
    return skuList;
  }
  async createRule(data) {
    const formData = { ruleVersion: data.ruleVersion, ...this.generateSKUList(data.skus) };
    return axiosFactory()
      .post(`${this.entity}/picktype_import_rules`, formData)
      .then((res) => {
        return res.data;
      });
  }
  async updateRule(data) {
    const formData = { ruleVersion: data.ruleVersion, ...this.generateSKUList(data.skus) };
    return axiosFactory()
      .put(`${this.entity}/picktype_import_rules/${data?.id}`, formData)
      .then((res) => {
        return res.data;
      });
  }
  async deleteRule(id) {
    return axiosFactory()
      .delete(`${this.entity}/picktype_import_rules/${id}`)
      .then((res) => {
        return res.data;
      });
  }
}

export default new OrdersService("orders");
