<template>
  <div :class="`sku-item sku-${index}-${sku.id}`" :key="`sku-${sku.id}`">
    <span class="sku-more-info material-symbols-outlined">
      package_2
      <md-tooltip class="modal-tooltip" md-direction="top">
        <table class="product-info-table">
          <tbody>
            <tr>
              <td>{{ $t("orders.sku") }}</td>
              <td>
                <b>{{ sku.shippingSkuName }}</b>
              </td>
            </tr>
            <tr>
              <td>{{ $t("orders.LWH") }}</td>
              <td>{{ sku.shippingSizeLength }} x {{ sku.shippingSizeWidth }} x {{ sku.shippingSizeHeight | formatUnit("cm") }}</td>
            </tr>
            <tr>
              <td>{{ $t("orders.shippingWeight") }}</td>
              <td>{{ sku.shippingSizeWeight | formatUnit("kg") }}</td>
            </tr>
            <tr class="table-separator">
              <td colspan="2"></td>
            </tr>
            <tr>
              <td>{{ $t("orders.masterSku") }}</td>
              <td>{{ sku.skuMasterbox }}</td>
            </tr>
            <tr>
              <td>{{ $t("orders.masterLWH") }}</td>
              <td>{{ sku.mbSkuLength | formatUnit("x") }} {{ sku.mbSkuWidth | formatUnit("x") }} {{ sku.mbSkuHeight | formatUnit("cm") }}</td>
            </tr>
            <tr>
              <td>{{ $t("orders.masterShippingWeight") }}</td>
              <td>{{ sku.mbSkuWeight | formatUnit("kg") }}</td>
            </tr>
            <tr>
              <td>{{ $t("orders.masterTotalSkus") }}</td>
              <td>{{ sku.mbSkuAmount | formatUnit("pcs") }}</td>
            </tr>
            <tr class="table-separator">
              <td colspan="2"></td>
            </tr>
            <tr>
              <td>{{ $t("orders.cabMerge") }}</td>
              <td>
                <b>{{ sku.cabQuantity | formatUnit("pcs") }}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </md-tooltip>
    </span>
    <div class="sku-info handle">
      <div>
        <b>{{ sku.shippingSkuName }}</b> - {{ sku.shippingSkuCarrier }}
      </div>
      <div class="location">{{ parseLocation?.(sku.shippingSkuLocation) }}</div>
    </div>
    <div class="badge">{{ sku.shippingSkuAmount }}</div>
  </div>
</template>
<script>
export default {
  props: {
    sku: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    parseLocation: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({}),
};
</script>
<style lang="scss">
.product-info-table {
  width: 100%;
  min-width: 250px;
  tr {
    td {
      text-align: left;
      font-size: 12px;
      padding: 0;
      &:first-child {
        color: #888;
      }
    }
  }
  .table-separator {
    height: 5px;
    // background-color: #ccc;
  }
}
.sku-item {
  text-align: left;
  display: flex;
  padding: 5px 10px;
  // border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 0px;
  align-items: center;
  gap: 10px;
  .sku-info {
    display: flex;
    flex-direction: column;
    line-height: 1.25;
    // gap: 5px;
    flex: 1 1 auto;
    cursor: grab;
    cursor: move;
    cursor: -webkit-grabbing;
    .location {
      font-size: 0.8rem;
    }
  }
  .badge {
    background-color: #03a9f4;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 1rem;
  }
  .sku-more-info {
    margin-left: auto;
    font-size: 30px !important;
  }
  &:hover {
    color: #03a9f4;
  }
}
</style>
