<template>
  <main class="split-order-container">
    <CSLoading :is-loading="loading"></CSLoading>
    <md-empty-state
      v-if="!loading && !this.originOrders.length"
      class="md-primary"
      md-icon="done"
      :md-label="$t('orders.messages.nothingInCheck')"
      :md-description="$t('orders.messages.noOrderNeedToSplit')"
    >
    </md-empty-state>

    <div v-if="originOrders.length > 1" class="progress-split-orders">
      <md-progress-bar md-mode="determinate" :md-value="percentCompleted"></md-progress-bar>

      <p>{{ $t("orders.NOrderRemaing", { count: remainingItems }) }}</p>
    </div>
    <div class="split-order-content vertical">
      <div v-if="originOrders.length > 1" class="list-order-wrapper">
        <header>
          <h3 class="md-title">{{ $t("orders.selectOrderToSplit") }}</h3>
          <md-content>
            <md-field :md-clearable="!isWarehouseAssigned">
              <label>{{ $t("wms.warehouse") }}</label>
              <md-select v-model="warehouseId" name="warehouseId" id="warehouseId" :disabled="isWarehouseAssigned" @md-selected="debounceApplyFilters">
                <md-option :key="i.id" v-for="i in warehouseOptions" :value="i.id">{{ i.name }}</md-option>
              </md-select>
            </md-field>
          </md-content>
        </header>

        <div class="list-orders">
          <OrderThumb
            :order="iorder"
            :key="`order-thumb-${index}-${iorder.id}`"
            :selected="newOrder?.id === iorder.id"
            v-for="(iorder, index) in originOrders"
            :index="index"
            @select="handleSelectOrder(iorder)"
          />
        </div>
      </div>
      <!-- <section> -->
      <div class="list-box">
        <section v-if="newOrder" class="order-box order-origin">
          <strong>{{ $t("orders.order") }}: {{ newOrder.customerInvoiceNumber }}</strong>
          <strong>{{ $t("orders.skus") }}</strong>
          <draggable class="sku-box box-origin" :list="newOrder.orderSkus" handle=".handle" :group="{ name: 'origin', pull: true, put: true }" @end="onDrop">
            <SkuItem :sku="sku" v-for="(sku, index) in newOrder.orderSkus" :index="index" :key="`sku-${sku.id}`" :parseLocation="parseLocation" />
          </draggable>
          <div class="order-footer">
            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ $t("orders.packageId") }}</label>
                  <md-input v-model="newOrder.customerOrderId" />
                </md-field>
              </div>
              <div class="md-layout-item md-size-50">
                <ValidationProvider name="addSkuModal.shippingSkuCarrier" :rules="'required'" v-slot="{ errors }">
                  <md-field>
                    <label for="shippingSkuCarrier">{{ $t("orders.carrier") }}</label>
                    <md-select v-model="newOrder.outboundCarriers" name="shippingSkuCarrier" id="shippingSkuCarrier">
                      <md-option :key="i.id" v-for="i in carriers" :value="i.name">{{ i.name }}</md-option>
                    </md-select>
                    <div class="md-error" v-if="errors[0]">
                      {{ $t(errors[0]) }}
                    </div>
                  </md-field>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </section>

        <div v-if="!!this.newOrder" class="split-icon">
          <md-button class="md-icon-button md-info" @click="splitOrder">
            <md-icon md-src="/img/arrow_split.svg" />
          </md-button>
        </div>
        <div class="split-box">
          <section v-for="(splitOrder, index) in splitOrders" :key="`split-${splitOrder.id}-${index}`" :class="['order-box order-splited', { 'drag-over': dragOverIndex === index }]">
            <strong>{{ $t("orders.order") }}: {{ splitOrder.customerInvoiceNumber }}</strong>

            <strong>SKUs</strong>
            <draggable
              :class="[`sku-box box-${index}`, { 'drag-over': dragOverIndex === index }]"
              :list="splitOrder.orderSkus"
              handle=".handle"
              :group="{ name: 'split-orders', pull: true, put: true }"
              @end="onDrop"
              :empty-text="$t('orders.dragSkuHere')"
            >
              <!-- <template #header>
                <div v-if="splitOrder.orderSkus.length === 0">{{ $t("orders.dragSkuHere") }}</div>
              </template> -->
              <SkuItem :sku="sku" :index="i" v-for="(sku, i) in splitOrder.orderSkus" :key="`sku-${sku.id}`" :parseLocation="parseLocation" />
            </draggable>
            <div class="order-footer">
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t("orders.packageId") }}</label>
                    <md-input v-model="splitOrder.customerOrderId" />
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50">
                  <ValidationProvider name="addSkuModal.shippingSkuCarrier" :rules="'required'" v-slot="{ errors }">
                    <md-field>
                      <label for="shippingSkuCarrier">{{ $t("orders.carrier") }}</label>
                      <md-select v-model="splitOrder.outboundCarriers" name="shippingSkuCarrier" id="shippingSkuCarrier">
                        <md-option :key="i.id" v-for="i in carriers" :value="i.name">{{ i.name }}</md-option>
                      </md-select>
                      <div class="md-error" v-if="errors[0]">
                        {{ $t(errors[0]) }}
                      </div>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <!-- </section> -->
    </div>

    <div class="card-actions">
      <div class="left-action">
        <md-button class="md-raised md-success" @click="splitOrder"> <md-icon>add</md-icon> </md-button>
      </div>
      <div class="right-action">
        <md-button class="md-raised md-accent" @click="$emit('close')">{{ $t("orders.cancel") }}</md-button>
        <md-button :disabled="shouldDisableSave" v-if="originOrders.length === 1" class="md-raised md-success" @click="handleSplitOrder">{{ $t("orders.save") }}</md-button>
        <md-button :disabled="shouldDisableSave" v-if="remainingItems > 1 && originOrders.length > 1" class="md-raised md-success" @click="handleSplitOrder">{{
          orders.length === 1 ? $t("orders.save") : $t("orders.saveAndNext")
        }}</md-button>
        <md-button :disabled="shouldDisableSave" v-if="remainingItems === 1 && originOrders.length > 1" class="md-raised md-success" @click="handleSplitOrder">{{
          orders.length === 1 ? $t("orders.save") : $t("orders.saveAndExit")
        }}</md-button>
      </div>
    </div>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>{{ $t("orders.enterShippingAmount") }}</md-dialog-title>
      <ValidationObserver v-slot="{ invalid }" ref="shippingAmountForm">
        <md-dialog-content>
          <p>
            {{ $t("orders.sku") }}:<strong> {{ draggedSku?.shippingSkuName }}</strong> <br />
            {{ $t("products.cabQuantity") }}: <strong>{{ draggedSku.cabQuantity || "N/A" }}</strong>
          </p>
          <ValidationProvider name="selectedQuantity" :rules="`required|min_value:1|max_value:${draggedSku.shippingSkuAmount}`" v-slot="{ errors }">
            <md-field>
              <label>{{ $t("orders.enterShippingAmount") }}</label>
              <md-input v-model="selectedQuantity" type="number" min="1" :max="draggedSku.shippingSkuAmount" />
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
            <div class="text-warning" style="font-size: 0.8rem" v-if="draggedSku.cabQuantity && selectedQuantity > draggedSku.cabQuantity && !errors[0]">
              {{ $t("orders.messages.shippingAmountWarning", { quantity: draggedSku.cabQuantity }) }}
            </div>
          </ValidationProvider>
        </md-dialog-content>
        <md-dialog-actions>
          <md-button class="md-acent" @click="handleCancelQuantity">{{ $t("orders.cancel") }}</md-button>
          <md-button class="md-primary" :disabled="invalid" @click="handleInputQuantity">{{ $t("orders.confirm") }}</md-button>
        </md-dialog-actions>
      </ValidationObserver>
    </md-dialog>
    <md-dialog-confirm
      :md-active.sync="showConfirmAmount"
      :md-title="$t('orders.confirm')"
      :md-content="$t('orders.messages.confirmOverAmount')"
      :md-confirm-text="$t('orders.confirm')"
      :md-cancel-text="$t('orders.back')"
      @md-cancel="showConfirmAmount = false"
      @md-confirm="handleConfirmQuantity"
    />
  </main>
</template>
<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
import SkuItem from "./SkuItem";
import OrderThumb from "./OrderThumb.vue";
import axiosFactory from "../../services/axios.factory";
import { assignedWarehouse } from "../../mixins/auth";
import CSLoading from "../../components/Loading/CSLoading.vue";

export default {
  components: { draggable, SkuItem, OrderThumb, CSLoading },
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
    parseLocation: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      allOrders: [],
      originOrders: [],
      order: null,
      newOrder: null,
      splitOrders: [],
      showDialog: false,
      selectedQuantity: 0,
      sourceOrder: null,
      targetOrder: null,
      draggedSku: null,
      dragEvent: null,
      dragOverIndex: null, // Track which box is being dragged over
      pullMode: true,
      loading: false,
      selectedOrderIndex: 0,
      showConfirmAmount: false,
      warehouseId: null,
      isLoading: false,
      remainingItems: 0,
    };
  },
  watch: {
    orders: {
      handler(val) {
        if (val && val.length > 0) {
          this.newOrder = structuredClone(val[0]);
          this.order = structuredClone(val[0]);
          this.originOrders = val;
          this.remainingItems = val.length;
        } else {
          this.originOrders = this.allOrders;
          this.remainingItems = this.allOrders.length;
          this.newOrder = structuredClone(this.allOrders[0]);
          this.order = structuredClone(this.allOrders[0]);
        }
      },
      immediate: true,
    },
    allOrders: {
      handler(val) {
        if (val.length > 0) {
          this.originOrders = val;
          this.remainingItems = val.length;
          this.newOrder = structuredClone(val[0]);
          this.order = structuredClone(val[0]);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState("warehouses", {
      warehouseOptions: "warehouses",
    }),
    isWarehouseAssigned() {
      return !!assignedWarehouse();
    },
    shouldDisableSave() {
      let shouldDisable = true;
      if (this.splitOrders.length > 0) {
        shouldDisable = this.splitOrders.some((order) => order.orderSkus.length === 0);
      } else {
        shouldDisable = this.newOrder.orderSkus.length === 0;
      }
      if (this.newOrder.orderSkus.length === 0) {
        shouldDisable = true;
      }
      return shouldDisable;
    },
    percentCompleted() {
      // return 80;
      const percent = ((this.originOrders.length - this.remainingItems) / this.originOrders.length) * 100;
      console.log("percent", this.remainingItems, this.originOrders.length, percent);
      return percent;
    },
    carriers() {
      const carrierItems = this.$store.state.shippingData.carriers || [];
      // carrierItems.push({ id: -1, name: "Spedition without number" });
      return carrierItems;
    },
    // remainingItems() {
    //   const remaining = this.originOrders.filter((order) => !order.isDone).length;
    //   console.log("remaining", remaining);
    //   return remaining;
    // },
  },
  methods: {
    debounceApplyFilters() {
      this.getCheckOrders({ warehouseId: this.warehouseId });
    },
    prepareUpdateOrder(originOrder) {
      const orderSkus = originOrder.orderSkus.map((sku) => {
        const parts = sku.shippingSkuLocation.split(":");
        const shippingSku = sku.id ? sku.shippingSkuLocation : parts[parts.length - 1];
        return {
          ...sku,
          shippingSkuLocation: shippingSku,
        };
      });
      return structuredClone({ ...originOrder, orderSkus, pickType: "CAB" });
    },
    prepareNewOrders(originOrders) {
      const formOrders = originOrders.map((order) => {
        const {
          customerIdNumber,
          customerInvoiceNumber,
          customerOrderId,
          phoneNumber,
          orderSkus,
          shippingAddressCompany,
          shippingAddressCountry,
          shippingAddressName,
          shippingAddressStreet,
          shippingAddressTown,
          shippingAddressZipcode,
          shippingStatusId,
        } = order;
        const newOrder = {
          customerIdNumber,
          customerInvoiceNumber,
          customerOrderId,
          phoneNumber,
          shippingAddressCompany,
          shippingAddressCountry,
          shippingAddressName,
          shippingAddressStreet,
          shippingAddressTown,
          shippingAddressZipcode,
          shippingStatusId,
          orderSkus: orderSkus.map((sku) => {
            const parts = sku.shippingSkuLocation.split(":");
            const shippingSku = parts[2] ?? null;
            return {
              shippingSkuCarrier: sku.shippingSkuCarrier,
              shippingSkuAmount: Number(sku.shippingSkuAmount).toString(),
              shippingSkuLocation: shippingSku,
              shippingSkuName: sku.shippingSkuName,
            };
          }),
          pickType: orderSkus.length === 1 && Number(orderSkus[0].shippingSkuAmount) === 1 ? "SQP" : "CAB",
        };
        return { ...newOrder };
      });
      return formOrders.filter((order) => order.orderSkus.length > 0);
    },
    async getCheckOrders(_params) {
      this.loading = true;
      const params = {
        ascending: 1,
        pageIndex: 1,
        pageSize: 1000,
        shippingStatusId: 1,
        // warehouseId: assignedWarehouse().id,
        pickTypes: "CHECK",
      };
      const mergeParams = { ...params, ..._params };
      return axiosFactory()
        .get(`/orders`, { params: mergeParams })
        .then((res) => {
          this.allOrders = res.data.data;
          this.loading = false;
          return res.data.data;
        })
        .catch(function (e) {});
    },
    async handleUpdateOrder(id, formData) {
      return axiosFactory().put(`/orders/${id}`, formData);
    },
    async handleSplitOrder() {
      const id = this.newOrder.id;
      let res;
      this.loading = true;
      try {
        // UPDATE current order as CAB
        if (this.splitOrders.length === 0) {
          // just add pickType = CAB for update order
          const updateFormData = this.prepareUpdateOrder(this.newOrder);
          res = await this.handleUpdateOrder(id, updateFormData);
        } else {
          // Split Order
          const updateOrder = this.prepareNewOrders([this.newOrder]);
          const newOrders = this.prepareNewOrders(this.splitOrders);
          const formData = [{ ...updateOrder[0], id: 0 }, ...newOrders];
          res = await axiosFactory().post(`/orders/${id}/splits`, formData);
        }
        console.log("Split order success", res);
        this.resetFormData();
        this.originOrders[this.selectedOrderIndex]["isDone"] = true;
        this.remainingItems -= 1;
        this.nextOrder();
        this.$notify({
          timeout: 2500,
          message: this.$t("orders.messages.splitOrderSuccess"),
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "success",
        });
        this.loading = false;
        if (this.originOrders.length === 1 || this.remainingItems < 1) {
          this.$emit("updated", res);
        }
      } catch (err) {
        console.error("Split order failed", err);
        this.$notify({
          timeout: 2500,
          message: this.$t("orders.messages.splitOrderFailed"),
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "error",
        });
        this.loading = false;
      }
      // axiosFactory()
      //   .post(`/orders/${id}/splits`, formData)
      //   .then((res) => {
      //     console.log("Split order success", res);
      //     this.resetFormData();
      //     this.originOrders[this.selectedOrderIndex]["isDone"] = true;
      //     this.remainingItems -= 1;
      //     // this.$set(this.originOrders, this.selectedOrderIndex, {
      //     //   ...this.originOrders[this.selectedOrderIndex],
      //     //   isDone: true,
      //     // });
      //     this.nextOrder();
      //     this.$notify({
      //       timeout: 2500,
      //       message: this.$t("orders.messages.splitOrderSuccess"),
      //       icon: "add_alert",
      //       horizontalAlign: "right",
      //       verticalAlign: "top",
      //       type: "success",
      //     });
      //     this.loading = false;
      //     if (this.originOrders.length === 1 || this.remainingItems <= 1) {
      //       this.$emit("updated", res);
      //     }
      //   })
      //   .catch((err) => {
      //     console.error("Split order failed", err);
      //     this.$notify({
      //       timeout: 2500,
      //       message: this.$t("orders.messages.splitOrderFailed"),
      //       icon: "add_alert",
      //       horizontalAlign: "right",
      //       verticalAlign: "top",
      //       type: "error",
      //     });
      //     this.loading = false;
      //   });
    },
    nextOrder() {
      if (this.originOrders.length === 1) {
        return;
      }
      // find next item was not done
      const nextOrder = this.originOrders.find((order, index) => index > this.selectedOrderIndex && !order.isDone);
      if (nextOrder) {
        this.handleSelectOrder(nextOrder);
      } else {
        // try to find the first item was not done
        const firstOrder = this.originOrders.find((order) => !order.isDone);
        if (firstOrder) {
          this.handleSelectOrder(firstOrder);
        } else {
          this.$notify({
            timeout: 2500,
            message: this.$t("orders.messages.allOrdersDone"),
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        }
      }
    },
    resetFormData() {
      this.newOrder = [];
      this.order = null;
      this.splitOrders = [];
      this.showDialog = false;
      this.selectedQuantity = 0;
      this.sourceOrder = null;
      this.targetOrder = null;
      this.draggedSku = null;
      this.dragEvent = null;
      this.dragOverIndex = null;
    },
    handleSelectOrder(selectedOrder) {
      this.resetFormData();
      this.order = selectedOrder;
      this.newOrder = structuredClone(selectedOrder);
      const newIndex = this.originOrders.findIndex((order) => order.id === selectedOrder.id);
      this.selectedOrderIndex = newIndex;
    },
    splitOrder() {
      const currentCustomerOrderId = this.newOrder.customerOrderId.split("-");
      const nextIndex = currentCustomerOrderId.length > 0 ? parseInt(currentCustomerOrderId[1]) + this.splitOrders.length + 1 : 1;
      const newCustomerOrderId = `${currentCustomerOrderId[0]}-${nextIndex}`;
      this.splitOrders.unshift({ ...structuredClone(this.newOrder), orderSkus: [], customerOrderId: newCustomerOrderId });
    },

    onDrop(event) {
      this.dragOverIndex = null; // Reset after dropping
      this.dragEvent = { oldIndex: event.oldIndex, newIndex: event.newIndex, from: event.from, to: event.to };

      if (event.from !== event.to) {
        const sourceOrder = this.findOrderByElement(event.from) ?? this.newOrder;
        const targetOrder = this.findOrderByElement(event.to) ?? this.newOrder;

        // Get the dropped item
        let movedItem = structuredClone(targetOrder.orderSkus[event.newIndex]);

        this.sourceOrder = sourceOrder;
        this.targetOrder = targetOrder;
        this.draggedSku = movedItem;

        const qty = movedItem.shippingSkuAmount;
        this.selectedQuantity = 1;
        if (qty > 1) {
          this.showDialog = true;
        } else {
          this.confirmQuantity();
        }
      }
      return event;
    },
    handleCancelQuantity() {
      this.showDialog = false;
      this.selectedQuantity = 0;
      this.confirmQuantity();
    },
    handleConfirmQuantity() {
      this.showConfirmAmount = false;
      this.confirmQuantity();
    },
    handleInputQuantity() {
      if (this.draggedSku.cabQuantity && this.selectedQuantity > this.draggedSku.cabQuantity) {
        this.showConfirmAmount = true;
      } else {
        this.confirmQuantity();
      }
    },
    confirmQuantity() {
      this.showDialog = false;

      const qty = Number(this.selectedQuantity);
      // Validate input
      if (qty !== null && !isNaN(qty) && Number(qty) > 0) {
        let newQty = Number(qty);
        let remainingQty = this.draggedSku.shippingSkuAmount - newQty;

        if (remainingQty > 0) {
          const remainItem = {
            ...this.draggedSku,
            shippingSkuAmount: remainingQty,
          };
          this.sourceOrder.orderSkus.push(remainItem);
        }

        // Create a new item with the new quantity for the target order
        let newItem = { ...this.draggedSku, shippingSkuAmount: newQty };
        this.$set(this.targetOrder.orderSkus, this.dragEvent.newIndex, newItem);
        // Remove duplicate sku code from targetOrder
        // If  moved SKu has been duplicate, sum the quantity then remove duplicate sku code from targetOrder
        this.targetOrder.orderSkus = this.targetOrder.orderSkus.reduce((acc, sku) => {
          const existingSku = acc.find((s) => s.id === sku.id);
          if (existingSku) {
            existingSku.shippingSkuAmount += sku.shippingSkuAmount;
          } else {
            acc.push(sku);
          }
          return acc;
        }, []);
      } else {
        // If input is invalid, move the item back
        this.sourceOrder.orderSkus.push(this.targetOrder.orderSkus.splice(this.dragEvent.newIndex, 1)[0]);
      }
      this.sourceOrder = null;
      this.targetOrder = null;
      this.draggedSku = null;
      this.dragEvent = null;
    },
    // Find the order by its dragged list
    findOrderByElement(el) {
      const match = el.className.match(/box-(\d+)/);
      const splitOrderIndex = match ? parseInt(match[1]) : null;

      if (splitOrderIndex !== null && this.splitOrders[splitOrderIndex]) {
        const targetOrder = this.splitOrders[splitOrderIndex];
        return targetOrder;
      }
      return null;
    },
  },
  created() {
    this.$store.dispatch("warehouses/getWarehouses");
    if (!this.orders || this.orders?.length === 0) {
      this.getCheckOrders().then((res) => {
        if (res && res.length > 0) {
          this.originOrders = res;
          this.newOrder = structuredClone(res[0]);
          this.order = structuredClone(res[0]);
        }
      });
    }
  },
};
</script>
<style lang="scss" src="./SplitOrder.scss"></style>

<style lang="scss"></style>
