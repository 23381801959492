<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div :class="`${className} modal-container`" v-click-outside="closeModal">
          <div class="modal-header">
            <slot name="header"></slot>
          </div>

          <div class="modal-body text-center">
            <slot name="body"></slot>
          </div>

          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "modal",
  props: ["className"],
  methods: {
    closeModal: function () {
      //this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.modal-fullscreen {
  width: 100vw;
  max-width: none !important;
  height: 100dvh;
  margin: 0;
  display: flex;
  flex-direction: column;
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    overflow-y: auto;
  }
}
.split-orders-modal-container {
  .modal-body {
    padding: 0 !important;
  }
  .modal-footer {
    padding: 0 !important;
  }
}
</style>
