<template>
  <div :class="[`order-thumb order-thumb-${order.id}`, { active: selected, isDone: order.isDone }]" @click="$emit('select', order)">
    <div class="order-info">
      <div>
        <b>#{{ order.customerInvoiceNumber }}</b> <time>{{ order.orderDate | formatDate }}</time>
      </div>

      <div class="sku">
        <span class="sku-chip" v-for="(sku, skuIndex) in order.skus.split(',')" :key="`${skuIndex}-${sku}`">{{ sku }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({}),
};
</script>
<style lang="scss">
.order-thumb {
  text-align: left;
  border-radius: 0px;
  border: 3px solid #fff;
  background-color: #fff;
  padding: 0.5rem 1rem;
  min-width: 200px;
  position: relative;
  // overflow: hidden;
  &.isDone {
    opacity: 0.65;
    pointer-events: none;
  }
  &.active {
    border-color: #4caf50;
    b,
    time {
      color: #4caf50;
    }
  }
  .sku {
    display: flex;
    gap: 3px;
    position: relative;
    overflow: hidden;
  }
  time {
    font-size: 0.8rem;
  }
  .sku-chip {
    display: inline-block;
    background-color: #e0e0e0;
    border-radius: 10px;
    padding: 0px 5px;
    margin-bottom: 5px;
    font-size: 0.7rem;
  }
}
</style>
