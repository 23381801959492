<template>
  <div class="text-left">
    <ValidationObserver v-slot="{}" ref="createForm">
      <ValidationProvider name="ruleVersion" rules="required" v-slot="{ errors }">
        <div class="mb-3">
          <!-- <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]"> -->
          <label>{{ $t("orderRules.ruleVersion") }}</label>
          <!-- <md-input v-model="post.ruleVersion" type="text"> </md-input> -->
          <div>
            <md-radio v-model="post.ruleVersion" value="SQP-Split">SQP-Split</md-radio>
            <md-radio v-model="post.ruleVersion" value="CAB-Merge">CAB-Merge</md-radio>
          </div>
          <div class="md-error" v-if="errors[0]">
            {{ $t(errors[0]) }}
          </div>
          <!-- </md-field> -->
        </div>
      </ValidationProvider>
      <ValidationProvider name="form.sku" rules="required" v-slot="{ errors }">
        <div class="wrap-v-select custom-wrap-v-select" style="text-align: left; margin-bottom: 15px">
          <label>{{ $t("wms.sku") }}</label>
          <v-select
            v-model="post.skus"
            :loading="loadingSku"
            multiple
            label="sku"
            :reduce="(item) => item.sku ?? item"
            :options="skuOptions"
            :filter="fuseSearchSKU"
            :placeholder="$t('wms.sku')"
            class="custom-select"
          >
            <template slot="no-options"> {{ $t("noRecord") }} </template>

            <template slot="option" slot-scope="option">
              <div :class="`d-center ${option.exact ? 'exact' : ''}`" v-html="option.sku"></div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center aaaa" v-html="option.sku"></div>
            </template>
          </v-select>
          <div class="md-error" v-if="errors[0]">
            {{ $t(errors[0]) }}
          </div>
        </div>
      </ValidationProvider>

      <div class="text-right gap-1">
        <md-button class="md-secondary mr-1" @click="handleCancel">{{ $t("news.cancel") }}</md-button>
        <md-button class="md-success mr-1" @click="savePost">{{ $t("news.save") }}</md-button>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import Fuse from "fuse.js";
import axiosFactory from "../../services/axios.factory";
import ordersService from "../../services/orders.service";

export default {
  props: {
    data: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      post: this.data
        ? structuredClone(this.data)
        : {
            ruleVersion: "SQP-Split",
            skus: [],
          },
      errors: {},
      isLoading: false,
      products: [],
      loadingSku: false,
    };
  },
  computed: {
    skuOptions() {
      return this.$store.state.shippingData.productSKUs?.map((p) => ({ value: p.sku, sku: p.sku, name: p.name })) || [];
    },
  },
  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    savePost() {
      if (this.post.id) {
        this.updatePost();
      } else {
        this.createPost();
      }
    },
    createPost() {
      this.$refs.createForm.validate().then((success) => {
        if (!success) {
          return;
        }

        const formData = this.post;
        this.isLoading = true;
        ordersService
          .createRule(formData)
          .then(() => {
            this.$emit("post-created");
          })
          .catch(() => {
            this.$notify({
              timeout: 2500,
              message: this.$t("orderRules.messages.error"),
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "error",
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
    updatePost() {
      this.$refs.createForm.validate().then((success) => {
        if (!success) {
          return;
        }

        const formData = this.post;
        this.isLoading = true;
        ordersService
          .updateRule(formData)
          .then(() => {
            this.$emit("post-updated");
          })
          .catch(() => {
            this.$notify({
              timeout: 2500,
              message: this.$t("orderRules.messages.error"),
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "error",
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
    fuseSearchSKU(_options, search) {
      const fuse = new Fuse(_options, {
        keys: ["value"],
        shouldSort: true,
        minMatchCharLength: 2,
        includeMatches: true,
      });
      const res = search.length
        ? fuse.search(search).map((pr) => {
            const { item } = pr;
            if (item.sku.toLowerCase() === search.toLowerCase()) {
              return { ...item, exact: true };
            } else {
              return { ...item };
            }
          })
        : fuse.list;
      return res;
    },
  },
  created() {},
};
</script>
<style lang="scss"></style>
