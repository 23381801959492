import Vue from "vue";
import VueI18n from "vue-i18n";
import TokenService, { LANGUAGE_SELECTED } from "../services/token.service";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1].split("_")[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function getDefaultLocale() {
  let locale = TokenService.get(LANGUAGE_SELECTED);
  if (locale) {
    document.documentElement.lang = locale;
    return locale;
  }

  if (!navigator.language) return "de";

  locale = navigator.language.split("-")[0];
  if (locale === "de") {
    document.documentElement.lang = "de";
    return "de";
  }

  document.documentElement.lang = "en";
  return "en";
}

export default new VueI18n({
  locale: getDefaultLocale(), // set locale
  fallbackLocale: "de",
  messages: loadLocaleMessages(),
});
