import Vue from "vue";
import Router from "vue-router";
import Swal from "sweetalert2";
import { isEmpty } from "lodash";

import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";

// Pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";
import RedirectPage from "@/pages/Dashboard/Redirect.vue";
import User from "@/pages/Dashboard/Pages/UserProfile.vue";

import Login from "@/pages/Dashboard/Pages/Login.vue";

import Users from "@/pages/Users/Users.vue";
import Products from "@/pages/Products/Products.vue";
import Suppliers from "@/pages/Suppliers/Suppliers.vue";
import Orders from "@/pages/Orders/Orders.vue";
import RulesSqp from "@/pages/RulesSqp/RulesSqp.vue";

import ShippingPlans from "@/pages/ShippingPlans/ShippingPlans.vue";
import Historical from "@/pages/ShippingPlans/Historical.vue";

import WmsDashboard from "@/pages/Wms/Dashboard.vue";
import WmsInventories from "@/pages/Wms/Inventories.vue";
import WmsWarehouses from "@/pages/Wms/Warehouses.vue";
import WmsStorageLocations from "@/pages/Wms/StorageLocations.vue";
import InventoryManagementExport from "@/pages/Wms/InventoryManagementExport.vue";

import { isAuthenticated } from "../mixins/auth";
import TokenService from "../services/token.service";

import i18n from "@/i18n/index.js";
import History from "../pages/Wms/History.vue";

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
  ],
};

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  linkExactActiveClass: "nav-item active",
  routes: [
    {
      path: "/",
      redirect: "/login",
      name: "Home",
      meta: {
        title: "Login",
      },
    },
    authPages,
    {
      path: "/app",
      component: DashboardLayout,
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          components: { default: Dashboard },
          meta: {
            title: "Dashboard",
          },
        },
        {
          path: "redirect",
          name: "RedirectPage",
          component: RedirectPage,
          meta: {
            title: "Dashboard",
          },
        },
        {
          path: "suppliers",
          name: "Suppliers",
          components: { default: Suppliers },
          meta: {
            title: "Suppliers",
            authorize: {
              menu: "Suppliers",
            },
          },
        },
        {
          path: "orders",
          name: "Orders",
          components: { default: Orders },
          meta: {
            title: "Orders",
            authorize: {
              menu: "Orders",
            },
          },
        },
        {
          path: "rules-sqp",
          name: "RulesSqp",
          components: { default: RulesSqp },
          meta: {
            title: "Rules SQP",
            authorize: {
              menu: "Orders",
            },
          },
        },
        {
          path: "products",
          name: "Products",
          components: { default: Products },
          meta: {
            title: "Products",
            authorize: {
              menu: "Products",
            },
          },
        },
        {
          path: "users",
          name: "Users",
          components: { default: Users },
          meta: {
            title: "Users",
          },
        },
        {
          path: "user-profile",
          name: "User Profile",
          components: { default: User },
          meta: {
            title: "User profile - Users",
          },
        },
        {
          path: "shipping-plans",
          name: "ShippingPlans",
          components: { default: ShippingPlans },
          meta: {
            title: "Shipping Plan",
            authorize: {
              menu: "ShippingPlans",
            },
          },
        },
        {
          path: "historical",
          name: "ShippingPlans-Historical",
          components: { default: Historical },
        },
        {
          path: "wms-dashboard",
          name: "Dashboard",
          components: { default: WmsDashboard },
          meta: {
            title: "Warehouse Dashboard",
            authorize: {
              menu: "Warehouses",
            },
          },
        },
        {
          path: "wms-inventories",
          name: "Inventories",
          components: { default: WmsInventories },
          meta: {
            title: "Warehouse Inventories",
            authorize: {
              menu: "Warehouses",
            },
          },
        },
        {
          path: "wms-warehouses",
          name: "Warehouses",
          components: { default: WmsWarehouses },
          meta: {
            title: "Warehouses",
            authorize: {
              menu: "Warehouses",
            },
          },
        },
        {
          path: "wms-storage-locations",
          name: "StorageLocations",
          components: { default: WmsStorageLocations },
          meta: {
            title: "Storage Locations",
            authorize: {
              menu: "StorageLocations",
            },
          },
        },
        {
          path: "wms-management-export",
          name: "InventoryManagementExport",
          components: { default: InventoryManagementExport },
          meta: {
            title: "Inventory Management Export",
            authorize: {
              menu: "InventoryManagementExport",
            },
          },
        },
        {
          path: "wms-history",
          name: "History",
          components: { default: History },
          meta: {
            title: "History",
            authorize: {
              menu: "Warehouses",
            },
          },
        },
      ],
    },
  ],
});

const DEFAULT_TITLE = "Operations Control Center by CS-Trading";
router.beforeEach((to, from, next) => {
  if (to.path.startsWith("/app")) {
    if (!isAuthenticated()) {
      return next({ path: "/login", query: { returnUrl: to.path } });
    }
  }

  const { authorize } = to.meta;
  if (authorize && authorize.menu) {
    const user = TokenService.getUser();
    if (!user.menuPermissions || !user.menuPermissions.includes(authorize.menu) || isEmpty(user.tableColumnPermissions) || isEmpty(user.tableColumnPermissions[authorize.menu])) {
      Swal.fire({
        title: i18n.t("permissionDenied"),
        text: i18n.t("permissionDeniedMessage"),
        icon: "error",
        confirmButtonText: "OK",
      });
      return next({ path: "/app/redirect", query: { to: "/app/dashboard", error: "noPermission" } });
    }
  }

  Vue.nextTick(() => {
    document.title = (to.meta && to.meta.title && `${to.meta.title} - ${DEFAULT_TITLE}`) || DEFAULT_TITLE;
  });

  next();
});

export default router;
