import axiosFactory from "./axios.factory";

class ShippingDataService {
  async getRecordStatuses() {
    const result = await axiosFactory().get("/shipping_data/record_statuses");
    return result.data.data;
  }

  async getRecordTags() {
    const result = await axiosFactory().get("/shipping_data/record_tags");
    return result.data.data;
  }

  async getSupplierNames() {
    const result = await axiosFactory().get("/shipping_data/supplier_names");
    return result.data.data;
  }

  async getContainerSizes() {
    const result = await axiosFactory().get("/shipping_data/container_sizes");
    return result.data.data;
  }

  async getContainerArrivalPlaces() {
    const result = await axiosFactory().get("/shipping_data/container_arrival_places");
    return result.data.data;
  }

  async getCarrierNames() {
    const result = await axiosFactory().get("/shipping_data/carrier_names");
    return result.data.data;
  }

  async getPaymentStatuses() {
    const result = await axiosFactory().get("/shipping_data/payment_statuses");
    return result.data.data;
  }

  async getShipmentPaidStatuses() {
    const result = await axiosFactory().get("/shipping_data/shipment_paid_statuses");
    return result.data.data;
  }

  async getCustomsPaidStatuses() {
    const result = await axiosFactory().get("/shipping_data/customs_paid_statuses");
    return result.data.data;
  }

  async getChinaStatuses() {
    const result = await axiosFactory().get("/shipping_data/china_statuses");
    return result.data.data;
  }

  async getLinkedProducts() {
    const result = await axiosFactory().get("/shipping_data/linked_products");
    return result.data.data;
  }

  async getChinaUsers() {
    const result = await axiosFactory().get("/shipping_data/china_users");
    return result.data.data;
  }

  async getAccountingStatuses() {
    const result = await axiosFactory().get("/shipping_data/accounting_statuses");
    return result.data.data;
  }

  async createSupplier(opts) {
    const result = await axiosFactory().post("/shipping_data/suppliers", opts);
    return result.data.data;
  }

  async editSupplier(opts) {
    const result = await axiosFactory().put("/shipping_data/suppliers", opts);
    return result.data.data;
  }

  async createProduct(opts) {
    const result = await axiosFactory().post("/shipping_data/products", opts);
    return result.data.data;
  }

  async editProduct(opts) {
    const result = await axiosFactory().put("/shipping_data/products", opts);
    return result.data.data;
  }

  async getProducts({ keywords }) {
    const result = await axiosFactory().get("/shipping_data/products?keywords=" + keywords);
    return result.data.data;
  }
  async getProductSKUs(params) {
    const result = await axiosFactory().get("/shipping_data/products", { params });
    return result.data.data;
  }

  async getShippingStatuses() {
    const result = await axiosFactory().get("/orders/shipping_statuses");
    return result.data.data;
  }

  async getCarriers() {
    const result = await axiosFactory().get("/shipping_data/carriers");
    return result.data.data;
  }
}

export default new ShippingDataService();
