<template>
  <div class="md-layout">
    <CSLoading :is-loading="isLoading"></CSLoading>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>history</md-icon>
          </div>
          <h4 class="title">{{ $t("wms.history") }}</h4>
        </md-card-header>
        <md-card-content>
          <p>{{ $t("wms.requireSelectSKU") }}</p>
          <div class="md-layout">
            <div class="md-layout-item md-size-20">
              <md-field md-clearable>
                <label>{{ $t("wms.keywords") }}</label>
                <md-input v-model="historyFilters.keywords" @input="debounceApplyFiltersHistory"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-20">
              <div class="wrap-v-select custom-wrap-v-select" style="text-align: left; margin-bottom: 0">
                <label>{{ $t("wms.sku") }} <sup style="color: red">*</sup></label>
                <v-select @input="handleSelectSku" v-model="historyFilters.sku" :options="skuOptions" :placeholder="$t('wms.sku')" class="custom-select"> </v-select>
              </div>
            </div>
            <div class="md-layout-item md-size-20">
              <div class="wrap-v-select custom-wrap-v-select" style="text-align: left; margin-bottom: 0">
                <label>{{ $t("wms.location") }}</label>
                <v-select :options="allLocationOptions" :placeholder="$t('wms.location')" @input="handleSelectHistoryLocation" class="custom-select"> </v-select>
              </div>
            </div>
            <div class="md-layout-item md-size-20">
              <md-field md-clearable>
                <label>{{ $t("wms.changeType") }}</label>
                <md-select v-model="historyFilters.changeType" name="filters.changeType" id="filters.changeType" @md-selected="debounceApplyFiltersHistory">
                  <md-option key="All" value="">{{ $t("wms.all") }}</md-option>
                  <md-option key="Add" value="Add">{{ $t("wms.add") }}</md-option>
                  <md-option key="Subtract" value="Subtract">{{ $t("wms.subtract") }}</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-20">
              <md-field md-clearable>
                <label>{{ $t("wms.historyType") }}</label>
                <md-select v-model="historyFilters.historyTypeIds" @md-selected="debounceApplyFiltersHistory" multiple>
                  <md-option :key="i.id" v-for="i in historyTypeOptions" :value="i.id">{{ getI18N("wms.statuses.historyTypes", i.name) }}</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-20">
              <div class="custom-wrap-v-select">
                <label class="date-title">{{ $t("wms.historyFromDateTime") }}</label>
                <simple-date-time-picker
                  :id="`historyFromDateTime`"
                  @datetimeChanged="(d) => dateFilterChanged(d, 'historyFromDateTime')"
                  @clearDatetime="() => clearDatetime('historyFromDateTime')"
                />
              </div>
            </div>
            <div class="md-layout-item md-size-20">
              <div class="custom-wrap-v-select">
                <label class="date-title">{{ $t("wms.historyToDateTime") }}</label>

                <simple-date-time-picker :id="`historyToDateTime`" @datetimeChanged="(d) => dateFilterChanged(d, 'historyToDateTime')" @clearDatetime="() => clearDatetime('historyToDateTime')" />
              </div>
            </div>
            <div class="md-layout-item md-size-20">
              <md-field md-clearable>
                <label>{{ $t("wms.reason") }}</label>
                <md-input v-model="historyFilters.reason" @input="debounceApplyFiltersHistory"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-20">
              <md-field md-clearable>
                <label>{{ $t("wms.changedBy") }}</label>
                <md-input v-model="historyFilters.changeBy" @input="debounceApplyFiltersHistory"></md-input>
              </md-field>
            </div>
          </div>
          <div>
            <v-server-table :columns="historyTableColumns" :options="historyTableOptions" ref="historyTable" class="table-warehouses-history table-sticky" id="table-warehouses-history">
              <template #warehouseName="{ row }">{{ row.storageLocation && row.storageLocation.warehouseName }}</template>
              <template #dateTime="{ row }">
                {{ row.dateTime | formatDateTime }}
              </template>
              <template #location="{ row }">
                {{ row.storageLocation && row.storageLocation.fullName }}
              </template>
              <template #changedType="{ row }">
                <span v-if="row.changedType === 'Add'">
                  <span class="material-symbols-outlined">add</span>
                </span>
                <span v-else-if="row.changedType === 'Substract' || row.changedType === 'Subtract'">
                  <span class="material-symbols-outlined">remove</span>
                </span>
                <span v-else>
                  {{ row.changedType }}
                </span>
              </template>
              <template #historyTypeName="{ row }"> {{ getI18N("wms.statuses.historyTypes", row.historyTypeName) }} </template>
              <template #changedBy="{ row }">
                {{ row.userWhoChangeName ? row.userWhoChangeName : "OCC" }}
              </template>
            </v-server-table>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import { debounce } from "lodash";
import SimpleDateTimePicker from "@/components/DateTimePicker/SimpleDateTimePicker.vue";

import CSLoading from "../../components/Loading/CSLoading.vue";
import axiosFactory from "../../services/axios.factory";
import { hasPermission } from "../../mixins/auth";
import { normalizeStatusName } from "../../mixins/formats";
export default {
  components: {
    CSLoading,
    SimpleDateTimePicker,
  },
  data() {
    return {
      isLoading: false,
      historyFilters: {
        storageLocationId: null,
        changeType: "",
        changeBy: "",
        reason: "",
        historyTypeId: null,
        historyTypeIds: [],
        location: "",
        sku: "",
        keywords: "",
      },
      locationOptions: [],
      options: [],
      locations: [],
      products: [],
      historyTable: null,
      historyTableColumns: ["sku", "warehouseName", "dateTime", "location", "inStockOld", "inStockNew", "quantity", "totalOld", "totalNew", "changedType", "reason", "changedBy", "historyTypeName"],
      historyTableOptions: {
        headings: {
          sku: () => this.$t("wms.sku"),
          warehouseName: () => this.$t("wms.warehouseName"),
          dateTime: () => this.$t("wms.dateTime"),
          location: () => this.$t("wms.location"),
          inStockOld: () => this.$t("wms.inStockOld"),
          inStockNew: () => this.$t("wms.inStockNew"),
          quantity: () => this.$t("wms.quantity"),
          totalOld: () => this.$t("wms.totalOld"),
          totalNew: () => this.$t("wms.totalNew"),
          changedType: () => this.$t("wms.changedType"),
          reason: () => this.$t("wms.reason"),
          changedBy: () => this.$t("wms.changedBy"),
          historyTypeName: () => this.$t("wms.historyType"),
        },
        perPage: 20,
        perPageValues: [20],
        filterable: false,
        sortable: [],
        params: {},
        resizableColumns: false,
        requestFunction: this.historyRequestFunction,
        responseAdapter: this.historyResponseFunction,
        rowClassCallback: this.rowClassCallback,
        pagination: {
          show: true,
        },
        texts: {
          count: this.$t("table.count"),
          limit: this.$t("table.records"),
          noResults: this.$t("table.noRecord"),
        },
        columnsClasses: {
          sku: "text-left",
          inStockOld: "text-end",
          inStockNew: "text-end",
          quantity: "text-end",
          totalOld: "text-end",
          totalNew: "text-end",
          changedType: "text-center",
          changedBy: "text-center",
          historyTypeName: "text-center",
        },
      },
    };
  },
  computed: {
    historyTypeOptions() {
      return this.$store.state.warehouses.historyTypes || [];
    },
    skuOptions() {
      return this.products.map((p) => p.sku) || [];
    },
    allLocationOptions() {
      return (
        this.locations.map((p) => ({
          value: p.id,
          label: p.fullName,
        })) || []
      );
    },
  },
  methods: {
    hasPermission(permissionName, tableName, columnName) {
      return hasPermission(permissionName, tableName, columnName);
    },
    dateFilterChanged(d, key) {
      this.historyFilters[key] = d.startDate;
      this.applyHistoryFilters({});
    },
    clearDatetime(key) {
      this.historyFilters[key] = null;
      this.applyHistoryFilters({});
    },
    debounceApplyFiltersHistory: debounce(function () {
      this.applyHistoryFilters();
    }, 300),
    handleSelectHistoryLocation(value) {
      this.historyFilters.storageLocationId = value?.value ?? undefined;
      this.debounceApplyFiltersHistory();
    },
    handleSelectSku(value) {
      this.historyFilters.sku = value ?? undefined;
      this.debounceApplyFiltersHistory();
    },
    applyHistoryFilters() {
      const { historyTypeIds, keywords, ...rest } = this.historyFilters;
      this.$refs.historyTable.options.params = {
        ...this.$refs.historyTable.options.params,
        ...rest,
        query: keywords,
        historyTypeIds: historyTypeIds?.join(","),
        pageIndex: 0,
      };
      this.currentPage = 1;
      this.$refs.historyTable.setPage(this.currentPage);
    },
    async historyRequestFunction(data) {
      const { page, limit, orderBy, ascending, ...filters } = data;
      this.currentPage = page;
      let params = {
        ...filters,
        pageIndex: page,
        pageSize: limit,
        orderBy,
        ascending,
      };

      this.isLoading = true;
      if (!this.historyFilters.sku) {
        return axiosFactory()
          .get(`/warehouses/sku/histories`, { params })
          .catch(function (e) {
            return false;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }

      return axiosFactory()
        .get(`/warehouses/${this.historyFilters.sku}/histories`, { params })
        .catch(function (e) {
          return false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    historyResponseFunction({ data }) {
      if (!data) {
        return {
          data: [],
          count: 0,
        };
      }
      return {
        data: data.data.items,
        count: data.data.totalItems,
      };
    },
    getI18N(prefix, statusName) {
      return this.$t(`${prefix}.${normalizeStatusName(statusName)}`);
    },
  },
  created() {
    this.$store
      .dispatch("warehouses/getLocationsByWarehouse", {
        warehouseId: 0,
        location: "",
        isPulldown: true,
      })
      .then((res) => {
        this.locations = res.filter((loc) => loc.isActive);
      });
    axiosFactory()
      .get("shipping_data/products", {
        params: { keywords: "", pageSize: 10000 },
      })
      .then((res) => {
        this.products = res.data.data;
      });
    Promise.all([this.$store.dispatch("warehouses/getHistoryTypes")]).then(() => {
      this.isLoading = false;
      this.applyHistoryFilters();
    });
  },
};
</script>
<style scoped>
.details .md-table-cell {
  text-align: left;
}
.mr-1 {
  margin-right: 15px;
}
.date-title {
  margin-bottom: 0;
  text-align: left;
  font-size: 0.85em;
  color: #888;
}
</style>
